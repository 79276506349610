import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { jumpToStep, setIsResultsAvailable } from "../../features/appSlice";
import { useTranslation } from "react-i18next";

export default function NoResults() {
  const { t } = useTranslation();
  const currentStep = useSelector(
    (state) => state.app.searchNavigation.currentStep
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(setIsResultsAvailable(false));
    }

    return () => {
      mounted = false;
      dispatch(setIsResultsAvailable(true));
    };
  }, []);

  return (
    <>
      <div className="noresults_search_div">
        <div className="noresults_search">
          {" "}
          <img
            src="images/close_white.svg"
            className="close_white mx-2"
            alt="icon"
            loading="lazy"
          />{" "}
          <span>{t("We have no result for your search")}</span>
        </div>
        <button
          type="button"
          className="btn btn-lg dropdown_modify_btn mx-3 text_regular"
          id="dropdown_modify_btn"
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "wb_click_modify",
                itemname: "WB click on the modify button",
              },
            });

            dispatch(jumpToStep(currentStep - 1));
          }}
        >
          <span> {t("Modify my search")} </span>{" "}
          <img
            src="images/chevron-right-black.svg"
            className="chevron_right_black"
          ></img>
        </button>
      </div>
      <div
        className=" btn btn-lg modify_big_btn"
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: "wb_click_modify",
              itemname: "WB click on the modify button",
            },
          });

          dispatch(jumpToStep(currentStep - 1));
        }}
      >
        {t("Modify my search")}
      </div>
    </>
  );
}
