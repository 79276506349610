import Home from "./pages/Home";
import AppCountryLangContext from "./country-lang-context";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTotalSteps } from "./features/appSlice";
import Errorpage from "./components/common/Errorpage";

function App() {
  const { isCountryLangValid, isCountryLangValidated, country } = useContext(
    AppCountryLangContext
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCountryLangValidated && isCountryLangValid) {
      const totalSteps = "GB" == country.toUpperCase() ? 6 : 5;
      dispatch(setTotalSteps(totalSteps));
    }
  }, [isCountryLangValidated, isCountryLangValid, country]);

  return (
    <>
      {false === isCountryLangValidated && "Loading...."}
      {true === isCountryLangValidated && false === isCountryLangValid && (
        <Errorpage />
      )}
      {true === isCountryLangValidated && true === isCountryLangValid && (
          <Home />
      )}
    </>
  );
}

export default App;
