import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    vehicleMakes: null,
    isVehicleMakesFetched: false,
    models: null,
    isModelsFetched: false,
    bodyStyle: null,
    isBodyStyleFetched: false,
    year: null,
    isYearFetched: false,
    mark: null,
    isMarkFetched: false,
    showDropDown: false,
    selectedDetails: {
      vehicleMake: null,
      model: null,
      bodyStyle: null,
      year: null,
      mark: null,
    },
    searchNavigation: {
      currentStep: 0,
      isHoverActive: false,
      hoverStep: 0,
    },
    isResultsFetched: false,
    result: {
      totalProducts: 0,
      categories: null,
      selectedCategories: [],
      totalAvailableProducts: 0,
      categorWiseProducts: null,
      dimensions: null,
    },
    isResultAvailable: true,
    totalSteps: 5,
  },
  reducers: {
    jumpToStep: (state, action) => {
      state.searchNavigation = {
        ...state.searchNavigation,
        isHoverActive: false,
        currentStep: action.payload,
      };
    },
    setVehicleMakes: (state, action) => {
      state.vehicleMakes = action.payload;
      state.isVehicleMakesFetched = true;
    },
    setSelectedVehicleMake: (state, action) => {
      let searchVehicleMake = state.vehicleMakes.filter((item, index) => {
        return action.payload === item.value;
      });

      state.selectedDetails = {
        ...state.selectedDetails,
        vehicleMake: searchVehicleMake[0],
      };
    },
    setModels: (state, action) => {
      state.models = action.payload;
      state.isModelsFetched = true;
    },
    setSelectedModel: (state, action) => {
      let searchModel = state.models.filter((item, index) => {
        return action.payload === item.value;
      });

      state.selectedDetails = {
        ...state.selectedDetails,
        model: searchModel[0],
      };
    },
    setBodyStyles: (state, action) => {
      state.bodyStyle = action.payload;
      state.isBodyStyleFetched = true;
    },
    setSelectedBodyStyle: (state, action) => {
      let searchModel = state.bodyStyle.filter((item, index) => {
        return action.payload === item.value;
      });

      state.selectedDetails = {
        ...state.selectedDetails,
        bodyStyle: searchModel[0],
      };
    },
    setYear: (state, action) => {
      state.year = action.payload;
      state.isYearFetched = true;
    },
    setMarks: (state, action) => {
      state.mark = action.payload;
      state.isMarkFetched = true;
    },
    setSelectedYear: (state, action) => {
      let searchModel = state.year.filter((item, index) => {
        return action.payload === item.value;
      });

      state.selectedDetails = {
        ...state.selectedDetails,
        year: searchModel[0],
      };
    },
    setSelectedMark: (state, action) => {
      let searchMark = state.mark.filter((item, index) => {
        return action.payload === item.value;
      });

      state.selectedDetails = {
        ...state.selectedDetails,
        mark: searchMark[0],
      };
    },
    setIsHoverActive: (state, action) => {
      state.searchNavigation = {
        ...state.searchNavigation,
        isHoverActive: action.payload,
      };
    },
    setHoverStep: (state, action) => {
      state.searchNavigation = {
        ...state.searchNavigation,
        hoverStep: action.payload,
      };
    },
    clearSelections: (state, action) => {
      let selectedStep = action.payload;
      state.isResultsFetched = false;
      state.result = {
        ...state.result,
        selectedCategories: [],
      };

      switch (selectedStep) {
        case 1:
          state.models = null;
          state.bodyStyle = null;
          state.year = null;
          state.isModelsFetched = false;
          state.isBodyStyleFetched = false;
          state.isYearFetched = false;
          state.isMarkFetched = false;

          state.selectedDetails = {
            ...state.selectedDetails,
            model: null,
            bodyStyle: null,
            year: null,
            mark: null,
          };

          break;

        case 2:
          state.bodyStyle = null;
          state.year = null;
          state.isBodyStyleFetched = false;
          state.isYearFetched = false;
          state.isMarkFetched = false;

          state.selectedDetails = {
            ...state.selectedDetails,
            bodyStyle: null,
            year: null,
            mark: null,
          };

          break;

        case 3:
          state.year = null;
          state.isYearFetched = false;
          state.isMarkFetched = false;

          state.selectedDetails = {
            ...state.selectedDetails,
            year: null,
            mark: null,
          };

          break;

        case 4:
          state.isMarkFetched = false;

          state.selectedDetails = {
            ...state.selectedDetails,
            mark: null,
          };

          break;

        default:
          break;
      }
    },
    processResult: (state, action) => {
      state.isResultsFetched = true;
      // let categorWiseProducts = action.payload.catalog;

      let productsList =  action.payload.products;
      let categories = action.payload.categories;
      let totalProducts = productsList.length;
      
      let categorWiseProductsLengths = [];
      let categorWiseProductsToShow = [];
      
      categories.forEach((category) => {
        var data_filter = productsList.filter( element => element.category == category);
        var matchedCategoryElements = data_filter.map(function (elem) {
          return elem
        });

        categorWiseProductsToShow[category] = data_filter; 
        
        categorWiseProductsLengths.push({
          name: category,
          totalProducts: matchedCategoryElements.length,
        });
        // categorWiseProductsLengths[category] = matchedCategoryElements.length;
      });


      /* START PREVIOUS CODE */
      // action.payload.categories.forEach((category) => {
      //   let categoryProductsLength = 0;

      //   if (categorWiseProducts[category]) {
      //     categoryProductsLength = Object.keys(
      //       categorWiseProducts[category]
      //     ).length;

      //     totalProducts += categoryProductsLength;
      //   }

      //   categories.push({
      //     name: category,
      //     totalProducts: categoryProductsLength,
      //   });
      // });
      /* END PREVIOUS CODE */
      

      state.result = {
        ...state.result,
        totalProducts: totalProducts,
        categories: categorWiseProductsLengths,
        dimensions: action.payload.dimensions,
        categorWiseProducts: categorWiseProductsToShow
      };
    },
    filterCategory: (state, action) => {
      let existingSelection = state.result.selectedCategories;
      let searchIndex = existingSelection.indexOf(action.payload);

      if (-1 === searchIndex) {
        existingSelection.push(action.payload);
      } else {
        existingSelection.splice(searchIndex, 1);
      }

      state.result = {
        ...state.result,
        selectedCategories: existingSelection,
      };
    },
    showHideDropDown: (state, action) => {
      state.showDropDown = action.payload;
    },
    setIsResultsAvailable: (state, action) => {
      state.isResultAvailable = action.payload;
    },
    setTotalSteps: (state, action) => {
      state.totalSteps = action.payload;
    },
  },
});

export const {
  jumpToStep,
  setVehicleMakes,
  setSelectedVehicleMake,
  setIsHoverActive,
  setHoverStep,
  setModels,
  setSelectedModel,
  setBodyStyles,
  setSelectedBodyStyle,
  setYear,
  setMarks,
  setSelectedYear,
  setSelectedMark,
  clearSelections,
  processResult,
  filterCategory,
  removeFilter,
  showHideDropDown,
  setIsResultsAvailable,
  setTotalSteps,
} = appSlice.actions;
export default appSlice.reducer;
