import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelections,
  jumpToStep,
  setSelectedYear,
  setYear,
} from "../../features/appSlice";
import { getYear } from "../../services/app";
import List from "../dropdown/List";
import Loader from "../common/Loader";
import NoResults from "./NoResults";
import SearchBar from "../search/SearchBar";
import SearchNavigation from "../search/SearchNavigation";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

export default function SelectYear() {
  const { t } = useTranslation();
  const isYearFetched = useSelector((state) => state.app.isYearFetched);
  const selectedDetails = useSelector((state) => state.app.selectedDetails);
  const year = useSelector((state) => state.app.year);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.getElementById("wrapper").scrollLeft += 400;
      dispatch(clearSelections(4));
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!isYearFetched) {
      getYear({
        make: selectedDetails.vehicleMake.value,
        model: selectedDetails.model.value,
        bodyStyle: selectedDetails.bodyStyle.value,
      })
        .then((response) => {
          dispatch(setYear(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [isYearFetched]);

  function nextStep(value) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wb_dropdown_selected_value",
        itemname: "WB select value in dropdown",
        wbsearchfiltername: "year",
        wbsearchfiltervalue: value,
        wbsearchparameters: {
          maker: selectedDetails.vehicleMake.value,
          model: selectedDetails.model.value,
          bodystyle: selectedDetails.bodyStyle.value,
          year: value,
          mark: "",
        },
      },
    });
    dispatch(setSelectedYear(value));
    dispatch(jumpToStep(5));
  }

  const [totalResults, setTotalResults] = useState();

  useEffect(() => {
    if (true === isYearFetched) {
      setTotalResults(year.length);
    }
  }, [isYearFetched, year]);

  return (
    <>
      <SearchNavigation step={4} />

      <div className="container-fluid">
        {0 === totalResults && <NoResults />}

        {0 !== totalResults && (
          <div id="dropdown_content_vehicle_make" className="dropdown-content">
            <SearchBar
              setSearchTerm={setSearchTerm}
              placeholder={t("Select the manufacturing year of your car")}
              label={t("Year")}
              autoFocusSearchInput={true}
            />

            {!totalResults && <Loader />}

            {totalResults > 0 && (
              <List
                data={year}
                callback={nextStep}
                searchTerm={searchTerm}
                traslationKey="search-year"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
