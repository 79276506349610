import { useTranslation } from "react-i18next";

export default function NeedHelpContent({ isMobile }) {
  const { t } = useTranslation();

  return (
    <div
      className={`${true === isMobile ? "need_help_div1" : "need_help_div"}`}
      style={{ display: "block" }}
    >
      <div className="need_help_title">{t("Need help?")}</div>
      {/* <div className="need_help_subtitle">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua
      </div> */}
      <div
        className={`d-flex justify-content-center ${
          true === isMobile ? "email_phone" : ""
        }`}
      >
        <div className="contact_email">
          <img src="images/contact_email.svg" className="contact_email_img" />
          <div className="contact_email_title">{t("Contact us by email")}</div>
          <div className="contact_email_subtitle">{t("info@michelin.com")}</div>
        </div>
        <div className="contact_phone">
          <img src="images/contact_phone.svg" className="contact_phone_img" />
          <div className="contact_phone_title">{t("Contact us by phone")}</div>
          <div className="contact_phone_subtitle">{t("0800 904 555")}</div>
        </div>
      </div>
    </div>
  );
}
