export default function Errorpage() {
  return (
    <div className="body_main">
      <div className="container-fluid">
        <div className="background_404">
          <div className="container-fluid d-flex flex-column align-items-center find_your_wipers">
            <div className="text_titlingbold error_404_title"> Error 404 </div>
            <div className="text_regular error_404_subtitle">
              {" "}
              The page you requested was not found or is temporarily
              unavailable.{" "}
            </div>
            <button
              type="button"
              className="btn btn-lg back_to_homepage mx-3 text_regular"
              id="back_to_homepage"
              onClick={() => {
                window.location.href = window.location.href.split("?")[0];
              }}
            >
              <span>Back to homepage</span>
              <img
                src="images/chevron-right-black.svg"
                alt="logo"
                className="chevron_right_black"
                loading="lazy"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
