import { useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { jumpToStep, showHideDropDown } from "../../features/appSlice";
import NeedHelpContent from "../help/NeedHelpContent";
import { useTranslation } from "react-i18next";

export default function SearchBar({
  label,
  placeholder,
  setSearchTerm,
  autoFocusSearchInput,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(
    (state) => state.app.searchNavigation.currentStep
  );
  const [showNeedHelp, setShowNeedHelpStatus] = useState(false);
  const showDropDown = useSelector((state) => state.app.showDropDown);

  return (
    <>
      <div className="search_div">
        <div className="maker_text">{label}</div>
        <input
          type="text"
          placeholder={placeholder}
          className="search"
          autoFocus={autoFocusSearchInput ? true : false}
          onClick={() => {
            if (0 === currentStep) {
              window.dataLayer.push({
                event: "wb_click_search_bar",
                itemname: "WB click search bar",
              });

              dispatch(jumpToStep(1));
            }
          }}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            dispatch(showHideDropDown(true));
          }}
        />
        {false === showNeedHelp && (
          <button
            type="button"
            className="btn btn-lg dropdown_need_help_btn mx-3 text_regular"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "wb_need_help",
                  itemname: "WB help button",
                },
              });

              setShowNeedHelpStatus(true);
              dispatch(showHideDropDown(false));
            }}
          >
            <span>{t("Need help?")} </span>{" "}
            <img
              src="images/chevron-right-black.svg"
              className="chevron_right_black"
            ></img>
          </button>
        )}

        {true === showNeedHelp && (
          <div
            className="need_help_close_div"
            onClick={() => {
              setShowNeedHelpStatus(false);
              dispatch(showHideDropDown(true));
            }}
          >
            <img
              src="images/cross_black.svg"
              className="need_help_close"
              alt={t("close help")}
            />
          </div>
        )}

        <div
          className="down_arrow"
          onClick={() => {
            if (0 === currentStep) {
              dispatch(jumpToStep(1));
            }

            dispatch(showHideDropDown(!showDropDown));
          }}
        >
          {" "}
          {true === showDropDown && <i className="fa fa-chevron-up"></i>}
          {false === showDropDown && <i className="fa fa-chevron-down"></i>}
        </div>
      </div>

      {true === showNeedHelp && <NeedHelpContent />}
    </>
  );
}
