import { useState, createContext, useEffect } from "react";
import i18next from "i18next";
import { validateCountryLangCombination } from "./services/app";

const DefaultCountryLangContext = {
  country: "US",
  lang: "en-us",
  isCountryLangValid: false,
  isCountryLangValidated: false,
};

export const AppCountryLangContext = createContext(DefaultCountryLangContext);

const { Provider } = AppCountryLangContext;

export const AppCountryLangProvider = (props) => {
  const [defaultStates, setDefaultStates] = useState(DefaultCountryLangContext);

  const validateCountryLang = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paramCountry = params.get("country") ?? "US";
    const paramLang = params.get("lang") ?? "en-us";

    sessionStorage.setItem("appCountry", paramCountry);
    sessionStorage.setItem("appLang", paramLang);

    try {
      await validateCountryLangCombination();
      setDefaultStates((prevState) => ({
        ...prevState,
        country: paramCountry,
        lang: paramLang,
        isCountryLangValidated: true,
        isCountryLangValid: true,
      }));
    } catch (error) {
      setDefaultStates((prevState) => ({
        ...prevState,
        isCountryLangValidated: true,
        isCountryLangValid: false,
      }));
    }
  };

  useEffect(() => {
    validateCountryLang();
  }, []);

  useEffect(() => {
    // console.log(defaultStates.lang + " asdasdasd");
    i18next.changeLanguage(defaultStates.lang);
  }, [defaultStates.lang]);

  return <Provider value={{ ...defaultStates }}>{props.children}</Provider>;
};

export default AppCountryLangContext;
