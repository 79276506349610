import { Trans } from "react-i18next";

export default function NotMatchingItem({ searchTerm }) {

  return (
    <div className="wrong_search">
      {" "}
      <img
        src="images/warning.svg"
        className="close_white mx-2"
        alt="icon"
        loading="lazy"
      />{" "}
      <span>
        <Trans i18nKey="NoResultsForSearchText">
        We couldn't find the "{{searchTerm}}", please check the spelling.
        </Trans>
      </span>
    </div>
  );
}
