import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processResult } from "../../features/appSlice";
import { getAvailableWiperBlades } from "../../services/app";
import ResultDetails from "./ResultDetails";
import SearchNavigation from "../search/SearchNavigation";
import NoResults from "../steps/NoResults";

export default function ResultsHeader() {
  const selectedDetails = useSelector((state) => state.app.selectedDetails);
  const dispatch = useDispatch();
  const totalProducts = useSelector((state) => state.app.result.totalProducts);
  const isResultsFetched = useSelector((state) => state.app.isResultsFetched);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getAvailableWiperBlades({
        make: selectedDetails.vehicleMake.value,
        model: selectedDetails.model.value,
        bodyStyle: selectedDetails.bodyStyle.value,
        year: selectedDetails.year.value,
        mark: selectedDetails.mark ? selectedDetails.mark.value : null,
      })
        .then((response) => {
          dispatch(processResult(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <SearchNavigation step={5} />
      {/* {true === isResultsFetched && 0 !== totalProducts && <ResultDetails />} */}
      {true === isResultsFetched && 0 === totalProducts && <NoResults />}
    </>
  );
}
