import { useTranslation } from "react-i18next";

export default function SelectedFilters({ selectedCategories, applyFilter }) {
  const { t } = useTranslation();

  return (
    selectedCategories.length > 0 && (
      <div className="selected_filters">
        {selectedCategories.map((category, key) => {
          return (
            <div
              className="filters_chip_selected selected"
              key={key}
              onClick={() => {
                applyFilter(category);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <span className="closebtn">
                <img
                  src="images/close.svg"
                  alt="icon"
                  height="14px"
                  loading="lazy"
                />
              </span>
              <span className="filter_selection">{t(category)}</span>
            </div>
          );
        })}
      </div>
    )
  );
}
