export const FR_COMMON_TRANSLATIONS = {
  "Find Your wipers": "Trouvez-vos essuie-glaces",
  "Select the make of your car": "Sélectionnez la marque de votre véhicule",
  "Need help?": "Besoin d'aide?",
  Maker: "Fabricante",
  "Vehicle Make": "Marque",
  "Select the model of your car": "Sélectionnez le modèle de votre véhicule",
  Model: "Modèle",
  Bodystyle: "Style de carrosserie",
  Year: "Année",
  "car model": "car model",
  "Select the bodystyle of your car":
    "Sélectionnez le style de carrosserie de votre véhicule",
  "Select the manufacturing year of your car":
    "Sélectionnez l’année de fabrication de votre véhicule",
  "Select the mark of your car": "Sélectionnez la version de votre véhicule",
  "We have no result for your search":
    "Nous n'avons pas de résultats pour votre recherche",
  "Modify my search": "Modifier ma recherche",
  "close help": "close help",
  "Contact us by email": "Contactez-nous par mail",
  "Contact us by phone": "Contactez-nous par téléphone",
  Close: "Fermer",
  Mark: "Constructeur",
  "Hide details": "Cacher les détails",
  "Show details": "Montrer les détails",
  Driver: "Conducteur",
  Passenger: "Passager",
  Rear: "Arrière",
  Result: "Résultat(s)",
  Filters: "Plus de filtres",
  Beam: "Faisceau",
  Hybrid: "Hybride",
  Conventional: "Conventionnelle",
  "Buy Now": "Acheter maintenant",
  "Instructions for your new wiper blades":
    "Guide d'installation pour vos nouveaux essuie-glaces",
  "How to install my wiper?": "Comment installer mes essuie-glaces? ",
  "How to remove my wiper?": "Comment enlever mes essuie-glaces?",
  "car maker": "car maker",
  totalResultsAndSelectedFilters:
    "{{ totalProducts }} Résultat: Balais d’essuie-glaces Michelin pour",
  "View less details": "Montrer les détails",
  "Hide more details": "Cacher les détails",
  NoResultsForSearchText:
    'Nous n’avons pas pu trouver les "{{ searchTerm }}", veuillez vérifier l’orthographe.',
  "Ultra Performance": "Ultra Performance",
  Standard: "Standard",
};
