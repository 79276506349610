import { useState } from "react";
import { useSelector } from "react-redux";
import NeedHelpContent from "./NeedHelpContent";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

export default function NeedHelpMobile() {
  const [showNeedHelp, setShowNeedHelpStatus] = useState(false);
  const isResultAvailable = useSelector((state) => state.app.isResultAvailable);
  const { t } = useTranslation();

  return (
    <>
      {true === isResultAvailable && (
        <div
          className=" btn btn-lg need_help_big_btn"
          id="need_help_big_btn"
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "wb_need_help",
                itemname: "WB help button",
              },
            });

            setShowNeedHelpStatus(true);
          }}
        >
          {t("Need help?")}
        </div>
      )}

      {true === showNeedHelp && (
        <div
          id="mobile_modal"
          className="mobile_modal"
          style={{ display: "block" }}
        >
          <div className="mobile_modal_content">
            <div
              className="need_help_close_div1"
              id="need_help_close_div1"
              onClick={() => {
                setShowNeedHelpStatus(false);
              }}
            >
              <img
                src="images/cross_black.svg"
                className="need_help_close"
                alt={t("Close")}
              />
            </div>

            <NeedHelpContent isMobile={true} />
          </div>
        </div>
      )}
    </>
  );
}
