import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN_GB } from "./lang/GB/en-GB";
import { TRANSLATIONS_EN_US } from "./lang/US/en-US";
import { TRANSLATIONS_FR_FR } from "./lang/FR/fr-FR";
import { TRANSLATIONS_FR_CA } from "./lang/CA/fr-CA";
import { TRANSLATIONS_EN_CA } from "./lang/CA/en-CA";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  "en-GB": {
    translation: TRANSLATIONS_EN_GB,
  },
  "en-US": {
    translation: TRANSLATIONS_EN_US,
  },
  "fr-FR": {
    translation: TRANSLATIONS_FR_FR,
  },
  "fr-CA": {
    translation: TRANSLATIONS_FR_CA,
  },
  "en-CA": {
    translation: TRANSLATIONS_EN_CA,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en-US", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
