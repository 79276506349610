export default function Loader() {
  return (
    <div className="loader loader-loading">
      <span className=" loader-content"></span>{" "}
      <span className=" loader-content"></span>{" "}
      <span className=" loader-content"></span>{" "}
      <span className=" loader-content"></span>
    </div>
  );
}
