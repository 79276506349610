import { useTranslation } from "react-i18next";

export default function FilterTypes({
  showHideFilters,
  categories,
  selectedCategories,
  setShowHideFiltersStatus,
  applyFilter,
  isMobile,
}) {
  const { t } = useTranslation();
  function isFilterApplied(categoryName) {
    return -1 !== selectedCategories.indexOf(categoryName);
  }

  return (
    true === showHideFilters && (
      <div
        className={`filters_chip_unselected unselected ${
          isMobile ? "mt-2" : ""
        }`}
        id="filters_chip_unselected"
      >
        <div className="filters_chip_unselected_type text_bold my-1">
          {t("Type")}
        </div>
        <div
          className={`${
            true === isMobile
              ? "d-flex my-2 filters_chip_unselected_cat_head"
              : "d-flex"
          } `}
        >
          {categories.map((category, index) => {
            const isSelected = isFilterApplied(category.name);
            const totalProducts = category.totalProducts;

            return (
              <div
                className={`filters_chip_unselected_cat ${
                  isSelected ? "selected_cat" : ""
                }
                ${0 === totalProducts ? "disabled" : ""}`}
                key={index}
                onClick={() => {
                  setShowHideFiltersStatus(false);
                  applyFilter(category.name);
                }}
                style={{ cursor: "pointer" }}
              >
                {t(category.name)}
                <div className="filters_count">({totalProducts})</div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}
