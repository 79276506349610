import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showHideDropDown } from "../../features/appSlice";
import NotMatchingItem from "../search/NoMatchingItem";

export default function List({ data, callback, searchTerm }) {
  const showDropDown = useSelector((state) => state.app.showDropDown);
  const dispatch = useDispatch();

  //create a new array by filtering the original array
  const filteredData = data.filter((el) => {
    //if no input the return the original
    if (searchTerm === "") {
      return el;
    }
    //return the item which contains the user input
    else {
      return el.label.toLowerCase().includes(searchTerm.toLowerCase());
    }
  });

  useEffect(() => {
    dispatch(showHideDropDown(true));
  }, []);

  return (
    <>
      {true === showDropDown && filteredData.length > 0 && (
        <ul className="find_your_wipers_search_ul">
          {filteredData.map((item, index) => {
            return (
              <li
                className="vehicle_make_source"
                onClick={() => {
                  callback(item.value);
                }}
                key={index}
              >
                <span className="dropdown_east_arrow"></span>
                {item.label}
              </li>
            );
          })}
        </ul>
      )}
      {0 === filteredData.length && <NotMatchingItem searchTerm={searchTerm} />}
    </>
  );
}
