import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import Loader from "../common/Loader";

export default function ResultDetails() {
  const { t } = useTranslation();
  const selectedDetails = useSelector((state) => state.app.selectedDetails);
  const dimensions = useSelector((state) => state.app.result.dimensions);
  const [showDetails, setShowDetailsStatus] = useState(true);
  const isResultsFetched = useSelector((state) => state.app.isResultsFetched);
  const totalProducts = useSelector((state) => state.app.result.totalProducts);

  useEffect(() => {
    // console.log(isResultsFetched);
    if (isResultsFetched) {
      TagManager.dataLayer({
        dataLayer: {
          event: "virtualPageView",
          virtualPage: "wb_results",
          pagecategory: "wiper blade selector search",
          sitesection: "browse-wiper-blades",
          itemname: "WB result",
          resultnumber: totalProducts,
          wbsearchparameters: {
            maker: selectedDetails.vehicleMake.value,
            model: selectedDetails.model.value,
            bodystyle: selectedDetails.bodyStyle.value,
            year: selectedDetails.year.value,
            mark: selectedDetails.mark?.value || "",
          },
        },
      });
    }
  }, [isResultsFetched]);

  return (
    true === isResultsFetched &&
    0 !== totalProducts && (
      <div className="find_your_wipers_background details_card">
        <div className="container-fluid " id="details_card">
          {true === isResultsFetched && (
            <>
              <button
                type="button"
                className={`collapse_card_header ${
                  showDetails ? "active" : "inactive"
                }`}
                onClick={() => {
                  setShowDetailsStatus(!showDetails);
                }}
              >
                {`${selectedDetails.vehicleMake.value} ${selectedDetails.model.value} ${selectedDetails.bodyStyle.value} ${selectedDetails.year.value}`}
                <span className="hide_details">
                  {true === showDetails && (
                    <>
                      <span className="hide_details_title">
                        {t("Hide details")}
                      </span>{" "}
                      <i className="fa fa-chevron-up"></i>
                    </>
                  )}
                  {false === showDetails && (
                    <>
                      <span className="hide_details_title">
                        {t("Show details")}
                      </span>{" "}
                      <i className="fa fa-chevron-down"></i>
                    </>
                  )}
                </span>
              </button>
              {true === showDetails && (
                <div className="card_content">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-2">
                      <img
                        src="images/icon_driver.svg"
                        width="100px"
                        height="48px"
                        alt="driver"
                        loading="lazy"
                        className="float-left me-2"
                      />

                      <div className="d-flex flex-column content_details">
                        <div className="content_details_1">{t("Driver")}</div>
                        <div className="content_details_2">
                          {dimensions.driver}
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-2">
                      <img
                        src="images/icon_passenger.svg"
                        width="100px"
                        height="48px"
                        alt="passenger"
                        loading="lazy"
                        className="float-left me-2"
                      />

                      <div className="d-flex flex-column content_details">
                        <div className="content_details_1">
                          {t("Passenger")}
                        </div>
                        <div className="content_details_2">
                          {dimensions.passenger}
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-2">
                      <img
                        src="images/icon_rear.svg"
                        width="100px"
                        height="48px"
                        alt="rear"
                        loading="lazy"
                        className="float-left me-2"
                      />

                      <div className="d-flex flex-column content_details">
                        <div className="content_details_1">{t("Rear")}</div>
                        <div className="content_details_2">
                          {dimensions.rear}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  );
}
