import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import InstallationGuide from "./InstallationGuide";
import { useTranslation } from "react-i18next";

export default function ListItem({ product }) {
  const { t } = useTranslation();
  const descriptionCharacterDisplayLimit = 300;
  const [
    isDescriptionCharacterLimitExceeeded,
    setDescriptionCharacterLimitStatus,
  ] = useState(false);
  const [showFullDescription, setShowFullDescriptionStatus] = useState(false);

  useEffect(() => {
    setDescriptionCharacterLimitStatus(
      product.description.length > descriptionCharacterDisplayLimit
    );
  }, [product]);

  function getDescription() {
    if (
      true === isDescriptionCharacterLimitExceeeded &&
      false === showFullDescription
    ) {
      return (
        product.description.substring(0, descriptionCharacterDisplayLimit) +
        "..."
      );
    } else {
      return product.description;
    }
  }

  function openProductBuyNowLink(link) {
    TagManager.dataLayer({
      dataLayer: {
        event: "lead generated",
        navItemDesc: "WB results",
        ecommerce: {
          currencyCode: "EUR",
          add: {
            products: [
              {
                name: product.title, // CrossClimate 2,
                id: product.sub_brand_id, // 461,
                price: "",
                brand: "Michelin", // Michelin,
                category: product.category, // Pneus auto tyres/Pneus 4 saisons,
                variant: "Wiper Blade Model", // SKU,
                dimension29: "", // 13787,
                dimension30: "", // 3528709347266,
                dimension31: "", // 934726
              },
            ],
          },
        },
      },
    });

    window.open(link, "_blank");
  }

  return (
    <>
      <div className="background_cards_body"></div>
      <div className="cards_body mb-4">
        <div className="card_container">
          <div class="row">
            <div class="col-xxl-5 col-xl-5 col-lg-12 col-md-12  col-sm-12 ">
              <div class="cards_filter_desktop">{t(product.category)}</div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 order-xxl-1 order-xl-1 order-lg-2 order-md-2 order-sm-2 order-2 cards_filter_div">
              <div className="d-flex flex-column align-items-center">
                <div className="cards_filter_image">
                  <img
                    src={`${product.image_base_path}${product.images[0].image_path}`}
                    alt={product.short_name}
                    style={{ height: "inherit" }}
                  />
                </div>
                <div className="cards_filter_image2" alt="icon">
                  <img
                    src={`${product.image_base_path}${product.images[0].image_path}`}
                    alt={product.short_name}
                    style={{ height: "inherit" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 order-xxl-2 order-xl-2 order-lg-1 order-md-1 order-sm-1 order-1 cards_details">
              <div className="d-flex flex-column align-items-start justify-content-center">
                <div className="cards_filter_mobile my-3">
                  {t(product.category)}
                </div>
                <div
                  className="cards_details_product_name text_regular"
                  dangerouslySetInnerHTML={{ __html: product.title }}
                ></div>
                <div className="cards_details_product_desc text_regular">
                  <span
                    dangerouslySetInnerHTML={{ __html: getDescription() }}
                  ></span>
                </div>
                {true === isDescriptionCharacterLimitExceeeded && (
                  <div
                    className="cards_details_see_more"
                    onClick={() => {
                      setShowFullDescriptionStatus(!showFullDescription);
                    }}
                  >
                    {false === showFullDescription && (
                      <>
                        {t("View less details")}{" "}
                        <i className="fa fa-chevron-down ms-2"></i>
                      </>
                    )}

                    {true === showFullDescription && (
                      <>
                        {t("Hide more details")}{" "}
                        <i className="fa fa-chevron-up ms-2"></i>
                      </>
                    )}
                  </div>
                )}

                <button
                  className="btn cards_details_btn_desktop"
                  onClick={() => {
                    openProductBuyNowLink(product.buy_now_url);
                  }}
                >
                  <span>{t("Buy Now")} </span>
                  <img
                    src="images/chevron-right-black.svg"
                    alt="right-black"
                    className="chevron_right_black"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 cards_filter_div">
            <button
              className="btn cards_details_btn_mobile"
              onClick={() => {
                openProductBuyNowLink(product.buy_now_url);
              }}
            >
              <span>{t("Buy Now")} </span>
              <img
                src="images/chevron-right-black.svg"
                alt="right-black"
                className="chevron_right_black"
              />
            </button>
          </div>
        </div>

        <InstallationGuide
          installationVideoLink={product.installation_video_url}
          removalVideoLink={product.removal_video_url}
        />
      </div>
    </>
  );
}
