import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  jumpToStep,
  setHoverStep,
  setIsHoverActive,
} from "../../features/appSlice";

export default function NavItem({ label, stepNumber, additionalClass }) {
  const [isStepCompleted, setStepCompletedStatus] = useState(false);
  const dispatch = useDispatch();

  const isHoverActive = useSelector(
    (state) => state.app.searchNavigation.isHoverActive
  );

  const hoverStep = useSelector(
    (state) => state.app.searchNavigation.hoverStep
  );

  const currentStep = useSelector(
    (state) => state.app.searchNavigation.currentStep
  );

  useEffect(() => {
    setStepCompletedStatus(currentStep > stepNumber);
  }, [stepNumber, currentStep]);

  return (
    <li
      onMouseOver={() => {
        dispatch(setIsHoverActive(true));
        dispatch(setHoverStep(parseInt(stepNumber)));
        setHoverStep(parseInt(stepNumber));
      }}
      onMouseOut={() => {
        dispatch(setIsHoverActive(false));
        dispatch(setHoverStep(0));
      }}
      className={`dropdown search_dropdown ${stepNumber > 1 ? "rectangle" : ""}
      ${
        stepNumber > currentStep ||
        (true === isHoverActive && stepNumber > hoverStep)
          ? "disabled"
          : ""
      }
      ${currentStep > stepNumber ? "status_checked" : ""}
      ${additionalClass}
      `}
      id="search_dropdown_vehicle_make"
      onClick={() => {
        if (true === isStepCompleted) {
          dispatch(jumpToStep(parseInt(stepNumber)));
        }
      }}
    >
      <div className="dropbtn">
        <span className="dropbtn_name">{label}</span>
        {true === isStepCompleted && (
          <span
            className={`mx-1 ${
              false === isHoverActive ||
              (true === isHoverActive && stepNumber <= hoverStep)
                ? "edit"
                : "delete"
            }`}
          ></span>
        )}
        {false === isStepCompleted && <span className="mx-1"></span>}
      </div>

      {false === isHoverActive && (
        <div className="status_num">{stepNumber}</div>
      )}

      {(false === isHoverActive ||
        (true === isHoverActive && stepNumber < hoverStep)) &&
        true === isStepCompleted && (
          <div className="status_checked_tick">
            <img
              src="images/checkmark.svg"
              className="nav-icons mx-2"
              alt="icon"
              loading="lazy"
            />
          </div>
        )}
    </li>
  );
}
