import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../common/Loader";
import ListItem from "./ListItem";

export default function ResultList() {
  const categorWiseProducts = useSelector(
    (state) => state.app.result.categorWiseProducts
  );

  const selectedCategories = useSelector(
    (state) => state.app.result.selectedCategories
  );
  const isResultsFetched = useSelector((state) => state.app.isResultsFetched);

  return (
    <div
      className="cards_body_container container-fluid"
      style={{ position: "relative", minHeight: "100px" }}
    >
      {false === isResultsFetched && <Loader />}

      {true === isResultsFetched &&
        Object.keys(categorWiseProducts).map((category) => {
          if (!category) {
            return "";
          }

          return Object.keys(categorWiseProducts[category]).map((shortName) => {
            let product = categorWiseProducts[category][shortName];

            return (
              (0 === selectedCategories.length ||
                -1 !== selectedCategories.indexOf(category)) && (
                <ListItem
                  product={product}
                  key={shortName}
                />
              )
            );
          });
        })}
    </div>
  );
}
