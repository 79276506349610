import { useState } from "react";
import { useTranslation } from "react-i18next";

const InstallationGuide = ({ installationVideoLink, removalVideoLink }) => {
  const { t } = useTranslation();
  const isValidUrl = (value) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const hasValidInstallationRemovalLinks = () => {
    return isValidUrl(installationVideoLink) || isValidUrl(removalVideoLink)
      ? true
      : false;
  };

  const hasValidInstallationLink = () => {
    return isValidUrl(installationVideoLink);
  };

  const hasValidRemovalLink = () => {
    return isValidUrl(removalVideoLink);
  };

  const [showGuide, setShowGuideStatus] = useState();

  const getStyle = () => {
    if (true == showGuide) {
      return {
        display: "flex",
      };
    }

    return {};
  };
  return (
    true === hasValidInstallationRemovalLinks() && (
      <div className="bottom_card_container">
        <div className="row">
          <div
            className="col-xxl-5 col-xl-12 bottom_card_container_text"
            onClick={() => {
              setShowGuideStatus(true == showGuide ? false : true);
            }}
          >
            <span className="bottom_card_container_text_span">
              <img
                src="images/blue_info_icon.svg"
                alt="icon"
                className="bottom_card_container_img"
              />{" "}
              <span> {t("Instructions for your new wiper blades")}</span>
            </span>
            <span className="bottom_card_container_btn_details">
              {" "}
              <i
                className={`fa ${
                  !showGuide ? "fa-chevron-up" : "fa-chevron-down"
                }`}
              ></i>
            </span>
          </div>
          <div
            className="col-xxl-7 col-xl-12 bottom_card_container_btn"
            style={getStyle()}
          >
            <hr />
            <div className="bottom_card_container_btns_div row">
              <button
                className="btn bottom_card_container_btn_primary col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12"
                style={{
                  display: hasValidInstallationLink() ? "" : "none",
                }}
                onClick={() => {
                  window.open(installationVideoLink, "_blank");
                }}
              >
                <div className="bottom_card_button_label">
                  <img
                    src="images/video_icon.svg"
                    className="bottom_card_container_video_img"
                    alt="icon"
                  />
                  <span>{t("How to install my wiper?")}</span>
                </div>
                <img
                  src="images/chevron-right-white.svg"
                  className="chevron_right_white"
                />
              </button>
              <button
                className="btn bottom_card_container_btn_secondary col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12"
                style={{
                  display: hasValidRemovalLink() ? "" : "none",
                }}
                onClick={() => {
                  window.open(removalVideoLink, "_blank");
                }}
              >
                <div className="bottom_card_button_label">
                  <img
                    src="images/blue_video_icon.svg"
                    className="bottom_card_container_video_img"
                    alt="icon"
                  />
                  <span>{t("How to remove my wiper?")}</span>
                </div>
                <img
                  src="images/chevron-right-blue.svg"
                  className="chevron_right_blue"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default InstallationGuide;
