import { useTranslation } from "react-i18next";

export default function Title() {
  const { t } = useTranslation();

  return (
    <div className="container-fluid d-flex flex-column align-items-center find_your_wipers">
      <div className="text_titlingbold find_your_wipers_title">
        {t("Find Your wipers")}
      </div>
    </div>
  );
}
