import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelections,
  jumpToStep,
  setSelectedMark,
  setMarks,
} from "../../features/appSlice";
import { getMark, getYear } from "../../services/app";
import List from "../dropdown/List";
import Loader from "../common/Loader";
import NoResults from "./NoResults";
import SearchBar from "../search/SearchBar";
import SearchNavigation from "../search/SearchNavigation";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

export default function SelectMark() {
  const { t } = useTranslation();
  const isMarkFetched = useSelector((state) => state.app.isMarkFetched);
  const selectedDetails = useSelector((state) => state.app.selectedDetails);
  const mark = useSelector((state) => state.app.mark);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.getElementById("wrapper").scrollLeft += 400;
      dispatch(clearSelections(5));
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!isMarkFetched) {
      getMark({
        make: selectedDetails.vehicleMake.value,
        model: selectedDetails.model.value,
        bodyStyle: selectedDetails.bodyStyle.value,
        year: selectedDetails.year.value,
      })
        .then((response) => {
          dispatch(setMarks(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [isMarkFetched]);

  function nextStep(value) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wb_dropdown_selected_value",
        itemname: "WB select value in dropdown",
        wbsearchfiltername: "mark",
        wbsearchfiltervalue: value,
        wbsearchparameters: {
          maker: selectedDetails.vehicleMake.value,
          model: selectedDetails.model.value,
          bodystyle: selectedDetails.bodyStyle.value,
          year: selectedDetails.year.value,
          mark: value,
        },
      },
    });

    dispatch(setSelectedMark(value));
    dispatch(jumpToStep(6));
  }

  const [totalResults, setTotalResults] = useState();

  useEffect(() => {
    if (true === isMarkFetched) {
      setTotalResults(mark.length);
    }
  }, [isMarkFetched, mark]);

  return (
    <>
      <SearchNavigation step={5} />

      <div className="container-fluid">
        {0 === totalResults && <NoResults />}

        {0 !== totalResults && (
          <div
            id="dropdown_content_mark"
            className="dropdown-content dropdown_content_mark"
          >
            <SearchBar
              setSearchTerm={setSearchTerm}
              placeholder={t("Select the mark of your car")}
              label={t("Mark")}
              autoFocusSearchInput={true}
            />

            {!totalResults && <Loader />}

            {totalResults > 0 && (
              <List
                data={mark}
                callback={nextStep}
                searchTerm={searchTerm}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
