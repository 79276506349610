import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelections,
  jumpToStep,
  setSelectedVehicleMake,
  setVehicleMakes,
} from "../../features/appSlice";
import { getVehicleMakes } from "../../services/app";
import List from "../dropdown/List";
import Loader from "../common/Loader";
import NoResults from "./NoResults";
import SearchBar from "../search/SearchBar";
import SearchNavigation from "../search/SearchNavigation";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

export default function SelectVehicleMake() {
  const vehicleMakes = useSelector((state) => state.app.vehicleMakes);
  const isVehicleMakesFetched = useSelector(
    (state) => state.app.isVehicleMakesFetched
  );
  const [vehileMakeSearchTerm, setVehicleMakeSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(clearSelections(1));
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!isVehicleMakesFetched) {
      getVehicleMakes()
        .then((response) => {
          dispatch(setVehicleMakes(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [isVehicleMakesFetched]);

  function nextStep(value) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wb_dropdown_selected_value",
        itemname: "WB select value in dropdown",
        wbsearchfiltername: "Vehicle make",
        wbsearchfiltervalue: value,
        wbsearchparameters: {
          maker: value,
          model: "",
          bodystyle: "",
          year: "",
          mark: "",
        },
      },
    });

    dispatch(setSelectedVehicleMake(value));
    dispatch(jumpToStep(2));
  }

  const [totalResults, setTotalResults] = useState();

  useEffect(() => {
    if (true === isVehicleMakesFetched) {
      setTotalResults(vehicleMakes.length);
    }
  }, [isVehicleMakesFetched, vehicleMakes]);

  return (
    <>
      <SearchNavigation />

      <div className="container-fluid">
        {0 === totalResults && <NoResults />}

        {0 !== totalResults && (
          <div id="dropdown_content_vehicle_make" className="dropdown-content">
            <SearchBar
              setSearchTerm={setVehicleMakeSearchTerm}
              placeholder={t("Select the make of your car")}
              label={t("Vehicle Make")}
              autoFocusSearchInput={true}
            />

            {!totalResults && <Loader />}

            {totalResults > 0 && (
              <List
                data={vehicleMakes}
                callback={nextStep}
                searchTerm={vehileMakeSearchTerm}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
