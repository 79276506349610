import { useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { filterCategory } from "../../features/appSlice";
import FilterTypes from "./FilterTypes";
import SelectedFilters from "./SelectedFilters";

export default function ResultFilter() {
  const { t } = useTranslation();
  const categories = useSelector((state) => state.app.result.categories);

  const isResultsFetched = useSelector((state) => state.app.isResultsFetched);
  const selectedCategories = useSelector(
    (state) => state.app.result.selectedCategories
  );
  const totalProducts = useSelector((state) => state.app.result.totalProducts);
  const selectedDetails = useSelector((state) => state.app.selectedDetails);
  const [showHideFilters, setShowHideFiltersStatus] = useState(false);
  const dispatch = useDispatch();

  function applyFilter(category) {
    dispatch(filterCategory(category));
  }

  return (
    true === isResultsFetched &&
    totalProducts > 0 && (
      <>
        <div className="filters_div pb-1" id="filters_div">
          <div className="container-fluid">
            <div className="filters_div_title">
              <Trans i18nKey="totalResultsAndSelectedFilters">
                Result: {{ totalProducts }} Michelin Wiper blades for
              </Trans>{" "}
              {`${selectedDetails.vehicleMake.value} ${selectedDetails.model.value} ${selectedDetails.bodyStyle.value} ${selectedDetails.year.value}`}
            </div>
            <div className="d-flex flex-column filters_btn_chip_div">
              <button
                type="button"
                className="btn filters_btn"
                id="filters_btn"
                onClick={() => {
                  if (!showHideFilters) {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "wb_show_filters",
                        itemname: "WB click open filters",
                      },
                    });
                  } else {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "wb_close_filters",
                        itemname: "WB click close filters",
                      },
                    });
                  }

                  setShowHideFiltersStatus(!showHideFilters);
                }}
              >
                <span>
                  <img
                    src="images/filters_icon.svg"
                    className="filters_icon"
                    alt="icon"
                    loading="lazy"
                  />
                </span>
                {t("Filters")} <span>({selectedCategories.length})</span>
              </button>
              <div className="filters_chip_desktop">
                <FilterTypes
                  showHideFilters={showHideFilters}
                  categories={categories}
                  selectedCategories={selectedCategories}
                  setShowHideFiltersStatus={setShowHideFiltersStatus}
                  applyFilter={applyFilter}
                  isMobile={false}
                />

                <SelectedFilters
                  selectedCategories={selectedCategories}
                  applyFilter={applyFilter}
                />
              </div>
            </div>
          </div>
        </div>

        {true === showHideFilters && (
          <div
            id="myModal"
            className="modal_bottom"
            style={{ display: "block" }}
          >
            <div className="modal_content">
              <div className="rectangle_bar"></div>
              <div
                className="close"
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "wb_close_filters",
                      itemname: "WB click close filters",
                    },
                  });
                  setShowHideFiltersStatus(false);
                }}
              >
                {t("Close")}
              </div>
              <div className="modal_body">
                <div className="filters_chip_mobile">
                  <FilterTypes
                    showHideFilters={showHideFilters}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    setShowHideFiltersStatus={setShowHideFiltersStatus}
                    applyFilter={applyFilter}
                    isMobile={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
}
