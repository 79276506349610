import { useState } from "react";
import { useSelector } from "react-redux";
import Bodystyle from "../components/steps/SelectBodyStyle";
import Model from "../components/steps/SelectModel";
import ResultFilter from "../components/result/ResultFilter";
import ResultList from "../components/result/ResultList";
import ResultsHeader from "../components/result/ResultsHeader";
import SearchBar from "../components/search/SearchBar";
import Title from "../components/common/Title";
import VehicleMake from "../components/steps/SelectVehicleMake";
import Year from "../components/steps/SelectYear";
import NeedHelpMobile from "../components/help/NeedHelpMobile";
import ResultDetails from "../components/result/ResultDetails";
import { useTranslation } from "react-i18next";
import SelectMark from "../components/steps/SelectMark";

export default function Home() {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const currentStep = useSelector(
    (state) => state.app.searchNavigation.currentStep
  );
  const totalSteps = useSelector((state) => state.app.totalSteps);

  function gotoStep(stepNumber) {
    setStep(stepNumber);
  }

  const isResultStep = () => {
    return (
      (5 === totalSteps && 5 === currentStep) ||
      (6 === totalSteps && 6 === currentStep)
    );
  };

  const isMarkStep = () => {
    return 6 === totalSteps && 5 === currentStep;
  };

  return (
    <div className="body_main">
      <div className="container-fluid">
        <div className={`find_your_wipers_background step${currentStep}`}>
          <Title />

          {0 === currentStep && (
            <SearchBar
              gotoStep={gotoStep}
              step={step}
              placeholder={t("Select the make of your car")}
              label={t("Vehicle Make")}
            />
          )}
          {1 === currentStep && <VehicleMake />}
          {2 === currentStep && <Model />}
          {3 === currentStep && <Bodystyle />}
          {4 === currentStep && <Year />}
          {true === isMarkStep() && <SelectMark />}

          {isResultStep() && <ResultsHeader />}
          {!isResultStep() && <NeedHelpMobile />}
        </div>

        {isResultStep() && (
          <>
            <ResultDetails />
            <ResultFilter />
            <ResultList />
          </>
        )}
      </div>
    </div>
  );
}
