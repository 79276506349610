import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

const api = axios;
api.defaults.baseURL = apiBaseUrl;

api.interceptors.request.use(
  (config) => {
    const appCountry = sessionStorage.getItem("appCountry");
    const appLang = sessionStorage.getItem("appLang");

    if (appLang) {
      config.headers["lang"] = appLang;
      config.headers["country"] = appCountry;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.defaults.withCredentials = true;

export const authenticateFrontEnd = () => {
  return api.get(`/authenticate`);
};

export const getVehicleMakes = () => {
  return api.post(`/get-makes`);
};

export const getModels = (request) => {
  return api.post(`/get-model`, request);
};

export const getBody = (request) => {
  return api.post(`/get-body`, request);
};

export const getYear = (request) => {
  return api.post(`/get-year`, request);
};

export const getAvailableWiperBlades = (request) => {
  return api.post(`/get-parts`, request);
};

export const validateCountryLangCombination = (request) => {
  return api.post(`/check-country`, request);
};

export const getMark = (request) => {
  return api.post(`/get-mark`, request);
};
