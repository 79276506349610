import { useSelector } from "react-redux";
import NavItem from "./NavItem";
import { useTranslation } from "react-i18next";

export default function SearchNavigation() {
  const selectedDetails = useSelector((state) => state.app.selectedDetails);
  const totalSteps = useSelector((state) => state.app.totalSteps);
  const { t } = useTranslation();

  return (
    <div className="container-fluid dropdowns_div">
      <ul className="wiper_dropdowns wrapper" id="wrapper">
        <NavItem
          label={`${
            selectedDetails.vehicleMake
              ? selectedDetails.vehicleMake.label
              : t("Vehicle Make")
          } `}
          stepNumber={1}
          additionalClass="search_dropdown_vehicle_make"
        />

        <NavItem
          label={`${
            selectedDetails.model ? selectedDetails.model.label : t("Model")
          } `}
          stepNumber={2}
          additionalClass="search_dropdown_model"
        />

        <NavItem
          label={`${
            selectedDetails.bodyStyle
              ? selectedDetails.bodyStyle.label
              : t("Bodystyle")
          } `}
          stepNumber={3}
          additionalClass="search_dropdown_bodystyle"
        />

        <NavItem
          label={`${
            selectedDetails.year ? selectedDetails.year.label : t("Year")
          } `}
          stepNumber={4}
          additionalClass="search_dropdown_year"
        />

        {6 === totalSteps && (
          <NavItem
            label={`${
              selectedDetails.mark ? selectedDetails.mark.label : t("Mark")
            } `}
            stepNumber={5}
            additionalClass="search_dropdown_year"
          />
        )}
      </ul>
    </div>
  );
}
